import { Carousel } from "react-bootstrap";
import { Link } from "react-router-dom";

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchBanners } from "../hooxSlice";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";

const CarouselStyled = styled(Carousel)`
  & .carousel-control-prev,
  & .carousel-control-next {
    justify-content: flex-start;
    height: 40%;
    margin: auto;
    width: auto;
    background-color: rgba(55, 55, 55, 0.5);
    opacity: 1;
  }

  & .carousel-control-prev:hover,
  & .carousel-control-next:hover {
    background-color: rgba(55, 55, 55, 0.8);
  }

  & .carousel-control-next-icon,
  & .carousel-control-prev-icon {
    color: #fff;
  }

  & .carousel-indicators {
    margin-bottom: ${(props) => (props.ismobile ? "0px" : null)};
    button {
      width: ${(props) => (props.ismobile ? "10px" : "15px")};
      height: ${(props) => (props.ismobile ? "10px" : "15px")};
      border-radius: 50%;
    }

    .active {
      background-color: #bb00bb;
    }
  }
`;

function MainCarousel() {
  const dispatch = useDispatch();
  const selectedCategory = useSelector((state) => state.hoox.selectedCategory);
  const banners = useSelector((state) => state.hoox.banners);

  const isMobile = useMediaQuery({ query: "(max-width: 500px)" });

  useEffect(() => {
    //console.log('MainCarousel use effect');
    dispatch(fetchBanners(selectedCategory));
  }, [selectedCategory]);

  return (
    <CarouselStyled
      key={selectedCategory}
      controls={banners && banners.length > 1}
      ismobile={isMobile ? isMobile : undefined}
    >
      {banners &&
        banners.map((banner) => (
          <Carousel.Item key={banner.id}>
            <Link to={`/${banner.urlName}/${banner.guid}`}>
              <img
                className="bd-placeholder-img"
                style={{ height: "auto", width: "100%" }}
                src={banner.posters[0].url}
                alt=""
              />
            </Link>
            {/*
          <div className="container">
            <Carousel.Caption className="text-start">
              <Container>
                <Row>
                  <Col md={{ span: 5, offset: 7 }}>
                    <h1>{banner.title}</h1>
                    <p>{banner.synopsisSh}</p>
                    <p>15 MAYIS 2021 20:00</p>
                    <p><Link to={`/${banner.urlName}/${banner.id}/${4}`}>DETAYLAR</Link></p>
                  </Col>
                </Row>
              </Container>
            </Carousel.Caption>
          </div>
*/}
          </Carousel.Item>
        ))}
    </CarouselStyled>
  );
}

export default MainCarousel;
