import { useState } from "react";
import "./contracts.css";
import { useEffect } from "react";
import { getUserAggrementsWithCode } from "../../service/customerService";
import ReactMarkdown from "react-markdown";
import { Helmet } from "react-helmet";

function DistanceSellingContract() {
  const [contracts, setContracts] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    if (contracts === undefined) {
      getUserAggrementsWithCode("MSS") //getUserAggrements() //getContracts()
        .then((response) => {
          setContracts(response.data.sellingContract);
          //console.log("contract:", contracts);
        })
        .catch((error) => console.log(error));
    }
    setIsLoading(false);
  }, [contracts]);

  return (
    <>
      <div className="application">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Mesafeli Satış Sözleşmesi</title>
          <meta
            name="description"
            content="Muud medya dijital yayıncılığın Rtük onaylı tescilli markası olan Hoox.tv yeni nesil bir dijital video izleme platformdur. "
          />
        </Helmet>
      </div>
      <div
        className="container"
        style={{ marginTop: "10%", marginBottom: "10%" }}
      >
        {isLoading ? (
          <div
            className="showAllText"
            style={{
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div className="spinner"></div>
          </div>
        ) : (
          <ReactMarkdown children={contracts} />
        )}
      </div>
    </>
  );
}

export default DistanceSellingContract;
