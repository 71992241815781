import { useState } from "react";
import "./contracts.css";
import { useEffect } from "react";
import { getUserAggrementsWithCode } from "../../service/customerService";
import ReactMarkdown from "react-markdown";
import { Helmet } from "react-helmet";
function TermsOfUse() {
  const [contracts, setContracts] = useState(undefined);

  useEffect(() => {
    if (contracts === undefined) {
      getUserAggrementsWithCode("US")
        .then((response) => {
          setContracts(response.data.userAgrrement);
          //console.log("contract:", contracts);
        })
        .catch((error) => console.log(error));
    }
  }, [contracts]);

  return (
    <>
      <div className="application">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Kullanım Koşulları</title>
          <meta
            name="description"
            content="Muud medya dijital yayıncılığın Rtük onaylı tescilli markası olan Hoox.tv yeni nesil bir dijital video izleme platformdur. "
          />
        </Helmet>
      </div>
      <div
        className="container"
        style={{ marginTop: "10%", marginBottom: "10%" }}
      >
        <ReactMarkdown children={contracts} />
      </div>
    </>
  );
}

export default TermsOfUse;
