import React, { useEffect } from "react";
import { Placeholder } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { ContentWrapper } from "../components/commons/styled-bootstrap";
import MainCarousel from "../components/MainCarousel";
import MainTab from "../components/MainTab";
import { fetchCategories, selectCategory } from "../hooxSlice";
import { Helmet } from "react-helmet";
import MatomoTracking from "../components/MatomoTracking";

function Home() {
  const status = useSelector((state) => state.hoox.status);

  const dispatch = useDispatch();

  const { categoryId } = useParams();

  useEffect(() => {
    dispatch(fetchCategories());
  }, [dispatch]);

  useEffect(() => {
    const selectedCategory = categoryId ? parseInt(categoryId) : undefined;
    dispatch(selectCategory(selectedCategory));
  }, [dispatch, categoryId]);

  return (
    <>
      <MatomoTracking />
      <div className="application">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Hoox - AnaSayfa</title>
          <meta
            name="description"
            content="Muud medya dijital yayıncılığın Rtük onaylı tescilli markası olan Hoox.tv yeni nesil bir dijital video izleme platformdur. Keşfetmekten keyif alınacak..."
          />
        </Helmet>
      </div>
      <ContentWrapper>
        {status === "loading" ? (
          <Placeholder animation="glow">
            <Placeholder
              xs={12}
              style={{ height: "600px", background: "rgba(255,255,255,.4)" }}
            />
          </Placeholder>
        ) : (
          <MainCarousel />
        )}
      </ContentWrapper>
      <MainTab />
    </>
  );
}

export default Home;
