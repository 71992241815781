import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";

export const ProductPageTitle = styled.h1`
  color: #fff;
  font-size: 30px;
  letter-spacing: 4px;
  height: 3%;
`;

export const ProductBox = styled.div`
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  border: 2px solid
    ${(props) => (props.isActive ? "#BC00BC" : "rgba(18 214 223 / 30%)")};
  transform: scale(${(props) => (props.isActive ? "1.1" : "1")});
  min-height: 390px;
  cursor: pointer;
`;

export const ProductTitle = styled.h2`
  color: #bc00bc;
  font-size: 20px;
`;

export const ProductDescription = styled.div`
  color: #fff;
  font-family: "GilroyLight";
`;

export const ProductSwiper = styled(Swiper)`
  padding: 30px;
`;

export const ProductSwiperSlide = styled(SwiperSlide)`
  display: flex;
`;

export const ProductPrice = styled.div`
  color: #fff;
  font-size: 28px;
  small {
    font-size: 70%; /* Küçük font boyutu */
  }
`;

export const PaymentInfo = styled.div`
  color: #bcb6b6;
  font-family: "GilroyLight";
  font-size: 12px;
`;
