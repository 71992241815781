import ReactGA from "react-ga4";
import { getCookieConsentValue } from "react-cookie-consent";

const GA_IDS = {
  main: "GTM-5HFR8X5",
  //secondary: "AW-11153768994",
};

export function initGoogleAnalytic() {
  const isCookieEnabled = getCookieConsentValue();
  //if (isCookieEnabled) {
  //initEskimiPixel();
  //ReactGA.initialize(GA_IDS.main);
  //ReactGA.initialize(GA_IDS.secondary);
  //}
}

export function initEskimiPixel() {
  //window.esk("init", "31528");
}

export function trackEskimiPage() {
  const isCookieEnabled = getCookieConsentValue();
  //if (isCookieEnabled === "true") {
  //window.esk("track", "Conversion");
  //}
}
