import React, { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import "./welcome/styles.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import MatomoTracking from "../components/MatomoTracking";
import { useAuth } from "../components/AuthContext";
import styled from "styled-components";
import { Form, Button, Col, Row } from "react-bootstrap";

export const InfoField = styled.p`
  display: block;
  background-color: rgb(0 7 58);
  border-radius: 7px;
  border: 1px solid rgba(18 214 223 / 30%);
  width: 100%;
  padding: 10px;
`;

export const SubsFormButtons = styled(`Button`)`
  background-color: transparent;
  border-radius: 7px;
  border: 1px solid rgba(18 214 223 / 30%);
  &:hover {
    background-color: #bface282;
  }

  &:disabled {
    background-color: #40456b;
    border: 1px solid rgb(216 219 223 / 35%);
    color: rgb(216 219 223 / 35%);
  }
`;

export const ProductAreaTitle = styled.div`
  display: block;
  background-color: transparent;
  width: 100%;
  font-size: 18px;
  font-family: GilroyBold;
  margin-bottom: 20px;
`;

export const ProductAreaItem = styled.div`
  display: block;
  background-color: transparent;
  width: 100%;
  font-size: 13px;
  font-family: GilroyLight;
  margin-bottom: 10px;
`;

export const ProductAreaDesc = styled.ul`
  list-style-type: disc;
  padding-left: ${(props) => (props.isMobile ? "25" : "15")}px;
  padding-bottom: ${(props) => (props.isMobile ? "15" : "15")}px;
  font-family: GilroyLight;
  letter-spacing: 1px;
  li {
    color: white; /* Metin rengi beyaz yap */
    line-height: ${(props) => (props.isMobile ? "26" : "39")}px;
    font-size: ${(props) => (props.isMobile ? "16" : "24")}px;
  }

  /* Yuvarlak noktaların (bullet point'lerin) rengini ayarla */
  li::marker {
    color: #bc00bc;
  }
`;

const PanelArea = styled("div")`
  color: #fff;
  /* background-color: rgba(255, 255, 255, 0.25);*/
  border-radius: 20px;
  padding-top: ${(props) => (props.isMobile ? "10" : "30")}px;
  padding-bottom: ${(props) => (props.isMobile ? "10" : "30")}px;
  padding-left: ${(props) => (props.isMobile ? "30" : "120")}px;
  padding-right: ${(props) => (props.isMobile ? "30" : "120")}px;
  font-size: 13px;
  min-height: 485px;
  margin: ${(props) => (props.isMobile ? "100px 5px 10px 5px" : "2px")};
  width: ${(props) => (props.isMobile ? "95%" : "60%")};
  background-image: url(/assets/img/7GunLanding.png);
  background-size: ${(props) =>
    props.isMobile
      ? "cover"
      : "contain"}; /* Arka plan resmini alanı tam dolduracak şekilde ayarlar */
  background-position: center; /* Arka plan resmini ortalar */
  background-repeat: no-repeat; /* Tekrar etmesini engeller */
`;

const SubInfoText = styled("div")`
  font-size: ${(props) => (props.isMobile ? "10" : "12")}px;
  margin: "1px 3px 1px 3px";
  color: #cccacad1;
  text-align: center;
`;

function CampaignWelcome() {
  const campaignCode = useParams().code;
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: "(max-width: 720px)" });

  //const windowWidth = window.innerWidth;

  const { user } = useAuth();

  const { isLoggedIn, guid } = user;

  useEffect(() => {}, []);

  useEffect(() => {
    document.body.style.marginTop = 0;

    return () => {
      document.body.style.marginTop = null;
    };
  }, []);

  const startSubscription = async () => {
    navigate("/abonelik/paketler");
    // add verify Subscription
  };

  return (
    <>
      <MatomoTracking />
      <div className="application">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Hoox Kampanya</title>
          <meta
            name="description"
            content="Muud medya dijital yayıncılığın Rtük onaylı tescilli markası olan Hoox.tv yeni nesil bir dijital video izleme platformdur. Keşfetmekten keyif alınacak..."
          />
        </Helmet>
      </div>

      <>
        <div
          className="container"
          style={{ marginTop: isMobile ? "" : "15vh", marginBottom: "20vh" }}
        >
          <Row
            style={{
              fontFamily: "GilroyRegular",
              letterSpacing: "1px",
              display: "flex",
              justifyContent: "center", // Yatay hizalama
              alignItems: "center", // Dikey hizalama
            }}
          >
            <Col md={12} style={{ display: "flex", justifyContent: "center" }}>
              <PanelArea isMobile={isMobile}>
                <Form onSubmit={startSubscription}>
                  <>
                    <Form.Group className="mb-3">
                      <Row
                        style={{
                          marginTop: "30px",
                        }}
                      >
                        <Col
                          md={12}
                          style={{
                            display: "flex",
                            alignItems: "flex-end", // Alt hizalamayı sağlar
                          }}
                        >
                          <span
                            style={{
                              fontFamily: "GilroyBold",
                              letterSpacing: "1px",
                              fontSize: "96px",
                              lineHeight: "1", // Satır yüksekliğini sıkı tutar
                              marginBottom: "-6px", // Gereksiz boşlukları sıfırlar
                            }}
                          >
                            7
                          </span>
                          <span
                            style={{
                              fontFamily: "GilroyBold",
                              letterSpacing: "1px",
                              fontSize: "46px",
                              lineHeight: "1", // Satır yüksekliğini sıkı tutar
                              marginBottom: "0", // Gereksiz boşlukları sıfırlar
                              marginLeft: "-8px",
                            }}
                          >
                            GÜN
                          </span>
                          <span
                            style={{
                              fontFamily: "GilroyBold",
                              letterSpacing: "1px",
                              fontSize: "96px",
                              lineHeight: "1", // Satır yüksekliğini sıkı tutar
                              marginBottom: "-6px", // Gereksiz boşlukları sıfırlar
                              marginLeft: "20px",
                            }}
                          >
                            7
                          </span>
                          <span
                            style={{
                              fontFamily: "GilroyBold",
                              letterSpacing: "1px",
                              fontSize: "46px",
                              lineHeight: "1", // Satır yüksekliğini sıkı tutar
                              marginBottom: "0", // Gereksiz boşlukları sıfırlar
                              marginLeft: "-5px",
                            }}
                          >
                            TL
                          </span>
                        </Col>
                      </Row>
                      <Row
                        style={{
                          marginTop: "10px",
                          marginBottom: isMobile ? "30px" : "50px",
                        }}
                      >
                        <Col
                          md={12}
                          style={{
                            display: "flex",
                            flexDirection: "column", // İçerikleri dikey eksende hizalamak için
                            justifyContent: "flex-end", // Dikeyde en alta taşır
                            minHeight: "100%", // Tam yükseklik
                          }}
                        >
                          <span
                            style={{
                              fontFamily: "GilroyBold",
                              letterSpacing: "1px",
                              fontSize: isMobile ? "39px" : "49px",
                              lineHeight: "1", // Satır yüksekliğini sıkı tutar
                              marginBottom: "-6px", // Gereksiz boşlukları sıfırlar
                            }}
                          >
                            Tanışma Paketi
                          </span>
                        </Col>
                      </Row>
                    </Form.Group>

                    <ProductAreaDesc isMobile={isMobile}>
                      <li key={1}>Hoox'a özel yapımlar</li>
                      <li key={2}>Her hafta yeni içerikler</li>
                      <li key={3}>İstediğin yerde</li>
                      <li key={4}>Reklamsız</li>
                      <li key={5}>2 ayrı cihazdan izle</li>
                      <li key={6}>Full HD kalite izle</li>
                      <li key={7}>Planını istediğin zaman iptal edebilirsin</li>
                    </ProductAreaDesc>
                    <SubInfoText isMobile={isMobile}>
                      *Kampanya, sadece web sitesi üzerinden yapılan üyeliklerde
                      geçerlidir. Kampanya süresi sona erdiğinde iptal edilmeyen
                      abonelikler, aylık plan üzerinden otomatik 29.90TL olarak
                      ücretlendirilecektir.
                    </SubInfoText>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "15px",
                      }}
                    >
                      <Button
                        style={{ marginBottom: "10px", width: "50%" }}
                        className="btn btn-primary checkOutButton w-100"
                        onClick={(e) => startSubscription()}
                        //disabled={false} //user.email must end with .edu.tr and selected product if edu.
                      >
                        ABONE OL
                      </Button>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "15px",
                        cursor: "pointer",
                      }}
                    ></div>
                  </>
                </Form>
              </PanelArea>
            </Col>
          </Row>
        </div>
      </>
    </>
  );
}

export default CampaignWelcome;
