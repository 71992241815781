import { useEffect, useState } from "react";
import { useAuth } from "../components/AuthContext";
import { getProducts, getUserDetails } from "../service/contentService";
import {
  Form,
  Button,
  FormSelect,
  Col,
  Row,
  FormControl,
} from "react-bootstrap";
import styled from "styled-components";
import HooxModal, { HOOX_MODAL } from "../components/HooxModal";
import { verifySubscription } from "../service/customerService";
import { useNavigate } from "react-router";
import useModal from "../components/hooks/useModal";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";

export const InfoField = styled.p`
  display: block;
  background-color: rgb(0 7 58);
  border-radius: 7px;
  border: 1px solid rgba(18 214 223 / 30%);
  width: 100%;
  padding: 10px;
`;

export const SubsFormButtons = styled(`Button`)`
  background-color: transparent;
  border-radius: 7px;
  border: 1px solid rgba(18 214 223 / 30%);
  &:hover {
    background-color: #bface282;
  }

  &:disabled {
    background-color: #40456b;
    border: 1px solid rgb(216 219 223 / 35%);
    color: rgb(216 219 223 / 35%);
  }
`;

export const ProductAreaTitle = styled.div`
  display: block;
  background-color: transparent;
  width: 100%;
  font-size: 18px;
  font-family: GilroyBold;
  margin-bottom: 20px;
`;

export const ProductAreaItem = styled.div`
  display: block;
  background-color: transparent;
  width: 100%;
  font-size: 13px;
  font-family: GilroyLight;
  margin-bottom: 10px;
`;

export const ProductAreaDesc = styled.ul`
  list-style-type: disc;
  padding-left: ${(props) => (props.isMobile ? "20" : "50")}px;
  li {
    color: white; /* Metin rengi beyaz yap */
    line-height: 25px;
    font-size: ${(props) => (props.isMobile ? "12" : "16")}px;
  }

  /* Yuvarlak noktaların (bullet point'lerin) rengini ayarla */
  li::marker {
    color: #bc00bc;
  }
`;

export const ProductPrice = styled.div`
  color: #fff;
  font-size: ${(props) => (props.isMobile ? "18" : "28")}px;
  small {
    font-size: 70%; /* Küçük font boyutu */
  }
`;

const TabTitle = styled("div")`
  font-size: ${(props) => (props.isMobile ? "18" : "23")}px;
  font-weight: 500;
`;

const PanelArea = styled("div")`
  color: #fff;
  background-color: rgba(255, 255, 255, 0.25);
  border-radius: 20px;
  padding-top: ${(props) => (props.isMobile ? "10" : "30")}px;
  padding-bottom: ${(props) => (props.isMobile ? "10" : "30")}px;
  padding-left: ${(props) => (props.isMobile ? "10" : "90")}px;
  padding-right: ${(props) => (props.isMobile ? "10" : "90")}px;
  font-size: 13px;
  min-height: 485px;
  margin: 2px;
  width: ${(props) => (props.isMobile ? "95%" : "60%")};
`;

const Line = styled("hr")`
  color: #12d6df;
  opacity: 1;
`;

const SubInfoText = styled("div")`
  font-size: "12px";
  margin: "1px 3px 1px 3px";
  color: #cccacad1;
  text-align: center;
`;

const PackageOption = styled(`Row`)``;

function PackageSelection() {
  const { user } = useAuth();
  const [product, setProduct] = useState({});
  const [products, setProducts] = useState([]);
  const [userDetails, setUserDetails] = useState({});
  const [isEdu, setIsEdu] = useState(false);
  const navigate = useNavigate();
  const [productDescItems, setProductDescItems] = useState([]);
  const isMobile = useMediaQuery({ query: "(max-width: 500px)" });

  const { isShowing: isShowingFail, toggle: toggleFailModal } = useModal();

  const {
    isShowing: isShowingPackageSelection,
    toggle: togglePackageSelectionModal,
  } = useModal();

  const [failMessage, setFailMessage] = useState(null);

  useEffect(() => {
    getProducts().then((response) => {
      // TODO: select activeIndex logic
      if (response.data && response.data.length > 0) {
        setProducts(response.data);
        // İlk ürünü alıp products state'ine ekleyin
        //console.log("[response.data[0]:", response.data[0]);
        setProduct(response.data[0]);
      }
    });
  }, []);

  useEffect(() => {
    getUserDetails()
      .then((response) => {
        //console.log("getUserDetails.response:", response);
        if (response.code === 200) {
          setUserDetails(response.data);
          //console.log("getUserDetails.response.data:", response.data);
          const domain = response?.data?.email?.split("@")[1];
          setIsEdu(domain.toLowerCase().endsWith("edu.tr"));
        } else {
          navigate("/user/register");
        }
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    //console.log("Product:", product);
    setDescription();
  }, [product]);

  function setSelectedProduct(spId) {
    //console.log("e : ", e.target.value);
    const selectedProduct = products.find(
      (product) => product.id === parseInt(spId)
    );

    if (selectedProduct) {
      setProduct(selectedProduct);
    } else {
      console.warn(`Product with id ${spId} not found`);
    }
    //const selectedOption = e.target.options[e.target.selectedIndex];
    //const selectedProductId = parseInt(selectedOption.getAttribute("id"));
    //const selectedProductValue = selectedOption.getAttribute("value");

    //const selectedProductFromList = products.find(
    //  (product) => product.id === selectedProductId
    //);
    //setProduct(selectedProduct);
  }

  function setDescription() {
    if (product && product.description) {
      const stringParts = product?.description
        .split("|")
        .map((part) => part.trim());
      setProductDescItems(stringParts);
    } else {
      setProductDescItems([]);
    }
  }

  const startSubscription = async () => {
    // add verify Subscription
    if (product && product?.externalId) {
      verifySubscription(product?.externalId, user.guid).then((response) => {
        if (response?.code === 200) {
          navigate("/payment-direct/" + user.guid + "/" + product?.externalId);
        } else {
          setFailMessage(response?.message);
          toggleFailModal();
        }
      });
    } else {
      togglePackageSelectionModal();
    }
  };

  const textInput = (event) => {
    const { value } = event.target;

    if (/^\d$/.test(value[value.length - 1])) {
      event.target.value = value.slice(0, value.length - 1);
    }
  };

  return (
    <>
      <div
        className="container"
        style={{ marginTop: isMobile ? "" : "15vh", marginBottom: "20vh" }}
      >
        <Row
          style={{
            fontFamily: "GilroyRegular",
            letterSpacing: "1px",
            display: "flex",
            justifyContent: "center", // Yatay hizalama
            alignItems: "center", // Dikey hizalama
          }}
        >
          <Col md={12} style={{ display: "flex", justifyContent: "center" }}>
            <PanelArea isMobile={isMobile}>
              <Form onSubmit={startSubscription} style={{ marginTop: "30px" }}>
                {userDetails.status === "INACTIVE" ? (
                  <>
                    <Form.Group className="mb-3">
                      <TabTitle>ABONELİK PAKETLERİ</TabTitle>
                      {/**
                      <Row
                        style={{
                          marginTop: "20px",
                        }}
                      >
                        <Col
                          md={6}
                          style={{
                            display: "flex",
                            alignItems: "flex-end", // Alt hizalamayı sağlar
                          }}
                        >
                          <span
                            style={{
                              fontFamily: "GilroyBold",
                              letterSpacing: "1px",
                              fontSize: "96px",
                              lineHeight: "1", // Satır yüksekliğini sıkı tutar
                              marginBottom: "-6px", // Gereksiz boşlukları sıfırlar
                            }}
                          >
                            7
                          </span>
                          <span
                            style={{
                              fontFamily: "GilroyBold",
                              letterSpacing: "1px",
                              fontSize: "46px",
                              lineHeight: "1", // Satır yüksekliğini sıkı tutar
                              marginBottom: "0", // Gereksiz boşlukları sıfırlar
                              marginLeft: "-8px",
                            }}
                          >
                            GÜN
                          </span>
                          <span
                            style={{
                              fontFamily: "GilroyBold",
                              letterSpacing: "1px",
                              fontSize: "96px",
                              lineHeight: "1", // Satır yüksekliğini sıkı tutar
                              marginBottom: "-6px", // Gereksiz boşlukları sıfırlar
                              marginLeft: "20px",
                            }}
                          >
                            7
                          </span>
                          <span
                            style={{
                              fontFamily: "GilroyBold",
                              letterSpacing: "1px",
                              fontSize: "46px",
                              lineHeight: "1", // Satır yüksekliğini sıkı tutar
                              marginBottom: "0", // Gereksiz boşlukları sıfırlar
                              marginLeft: "-5px",
                            }}
                          >
                            TL
                          </span>
                        </Col>
                        <Col
                          md={6}
                          style={{
                            display: "flex",
                            flexDirection: "column", // İçerikleri dikey eksende hizalamak için
                            justifyContent: "flex-end", // Dikeyde en alta taşır
                            minHeight: "100%", // Tam yükseklik
                          }}
                        >
                          <span
                            style={{
                              fontFamily: "GilroyLight",
                              letterSpacing: "1px",
                              fontSize: "26px",
                              lineHeight: "1", // Satır yüksekliğini sıkı tutar
                              marginBottom: "0", // Gereksiz boşlukları sıfırlar
                            }}
                          >
                            Aylık pakete özel
                          </span>
                          <span
                            style={{
                              fontFamily: "GilroyLight",
                              letterSpacing: "1px",
                              fontSize: "26px",
                              lineHeight: "1", // Satır yüksekliğini sıkı tutar
                              marginBottom: "0", // Gereksiz boşlukları sıfırlar
                            }}
                          >
                            ilk 7 gün 7 TL
                          </span>
                        </Col>
                      </Row>
                      */}

                      {products &&
                        products.map((prd) => (
                          <div
                            key={prd.id}
                            onClick={() => setSelectedProduct(prd.id)} // Tüm satıra tıklanabilirlik ekliyoruz
                            style={{
                              border:
                                product?.id === prd?.id
                                  ? prd?.externalId === "7GUN"
                                    ? "2px solid #bc00bc"
                                    : "2px solid #12d6df"
                                  : "1px solid rgba(18, 214, 223, 0.3)",
                              color:
                                product?.id === prd?.id ? "white" : "#cccacad1",
                              padding: "10px",
                              borderRadius: "10px",
                              margin: "15px 3px 5px 3px",
                              cursor: "pointer", // Seçilebilirlik hissi vermek için
                            }}
                          >
                            <Row
                              style={{ alignItems: "center", width: "100%" }}
                            >
                              <Col xs={8}>
                                <TabTitle>
                                  {prd?.name +
                                    (prd?.externalId === "7GUN" ? " *" : "")}
                                </TabTitle>
                              </Col>
                              <Col xs={4} className="text-end">
                                <ProductPrice
                                  isMobile={isMobile}
                                  style={{
                                    color:
                                      product.id === prd.id
                                        ? "white"
                                        : "#cccacad1",
                                  }}
                                >
                                  {
                                    prd?.priceWithTax
                                      .toLocaleString("tr-TR", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })
                                      .split(",")[0]
                                  }
                                  ,
                                  <small>
                                    {
                                      prd?.priceWithTax
                                        .toLocaleString("tr-TR", {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        })
                                        .split(",")[1]
                                    }
                                  </small>{" "}
                                  TL.
                                </ProductPrice>
                              </Col>

                              {/** 
                              prd.id == 2 ? (
                                <Col
                                  xs={4}
                                  className="text-end"
                                  style={{ height: "0px" }}
                                >
                                  <img
                                    alt=""
                                    src="/assets/img/7tl.png"
                                    style={{
                                      height: 50,
                                      width: "auto",
                                      marginBottom: "0px",
                                      marginTop: "-65px",
                                      marginRight: "-41px",
                                    }}
                                  />
                                </Col>
                              ) : (
                                ""
                              )  */}
                            </Row>
                          </div>
                        ))}
                    </Form.Group>

                    <ProductAreaDesc isMobile={isMobile}>
                      {productDescItems.map((part, index) => (
                        <li key={index}>{part}</li>
                      ))}
                    </ProductAreaDesc>
                    <SubInfoText>
                      * 7 gün 7 TL kampanyası aylık plan a özel sunulan bir
                      kampanyadır. 7 gün sonunda iptal edilmemesi durumunda
                      üyeliğiniz otomatik aylık plan olarak devam edecektir.
                    </SubInfoText>
                    <SubInfoText>
                      * Eğitim Planı edu.tr ile biten eposta adresiyle
                      seçilebilir.
                    </SubInfoText>
                    <SubInfoText>
                      * Abonelikler iptel edilmediğinde otomatik yenilenir.
                    </SubInfoText>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "15px",
                      }}
                    >
                      <Button
                        style={{ marginBottom: "10px", width: "50%" }}
                        className="btn btn-primary checkOutButton w-100"
                        onClick={(e) => startSubscription()}
                        //disabled={false} //user.email must end with .edu.tr and selected product if edu.
                      >
                        ABONE OL
                      </Button>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "15px",
                        cursor: "pointer",
                      }}
                    >
                      <Link
                        key={"dontsubscribe"}
                        to={`/home`}
                        style={{ textDecoration: "none" }}
                      >
                        <SubInfoText>Abone Olmadan Devam Et</SubInfoText>
                      </Link>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </Form>
            </PanelArea>
          </Col>

          {/**
          <Col md={6}>
            <PanelArea
              isMobile={isMobile}
              style={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "transparent",
              }}
            >
              <Row
                style={{ minHeight: "300px", textAlign: "center" }}
                className="justify-content-center"
              >
                <img
                  alt=""
                  src="/assets/img/7gunadd.jpeg"
                  style={{
                    height: 600,
                    width: "auto",
                    marginBottom: "10px",
                    background:
                      "linear-gradient(to left, transparent, rgba(0, 0, 0, 0.5) 50%, transparent)",
                  }}
                />

                <SubInfoText>
                  * Eğitim Planı edu.tr ile biten eposta adresiyle seçilebilir.
                </SubInfoText>
                <SubInfoText>
                  * Abonelikler iptel edilmediğinde otomatik yenilenir.
                </SubInfoText>
              </Row>
            </PanelArea>

            {/* 

<Form.Group className="mb-3">
                <Form.Label htmlFor="cmpCode">Kampanya Kodu</Form.Label>
                <FormControl
                  id="cmpCode"
                  name="cmpCode"
                  placeholder="Kampanya Kodu"
                  onChange={textInput}
                />
              </Form.Group>

***
          </Col>
  */}
        </Row>

        <HooxModal
          isShowing={isShowingPackageSelection}
          hide={togglePackageSelectionModal}
          title="Paket Seçimi"
          type={HOOX_MODAL.INFO}
        >
          <p style={{ color: "#ffffff" }}>
            İlerlemek için bir paket seçmelisiniz.
          </p>
        </HooxModal>
        <HooxModal
          isShowing={isShowingFail}
          hide={toggleFailModal}
          title="İşlem Başarısız"
          type={HOOX_MODAL.INFO}
        >
          <p style={{ color: "#ffffff" }}>{failMessage}</p>
        </HooxModal>
      </div>
    </>
  );
}
export default PackageSelection;
