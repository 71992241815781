import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Form, Button } from "react-bootstrap";

import { resetPasswordV2 } from "../service/contentService";
import HooxModal, { HOOX_MODAL } from "../components/HooxModal";
import useModal from "../components/hooks/useModal";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import { useMediaQuery } from "react-responsive";

const FormControl = styled(Form.Control)`
  background-color: rgba(0, 0, 0, 0.8) !important;
  border-radius: 7px !important;
  resize: none;
  height: 40px;
  font: normal normal normal 14px Montserrat;
  &:focus-visible {
    outline: rgba(0, 0, 0, 0.8) !important;
  }
`;

const ShowPasswordIcon = styled.i`
  position: absolute;
  top: 25%;
  right: 12px;
  cursor: pointer;
`;

const InfoText = styled.div`
  //width: ${(props) => (props.isMobile ? "80%" : "80%")};

  color: #ffffff;
  font-family: "GilroyLight";
  text-decoration: none;
  font-size: ${(props) => (props.isMobile ? "20" : "24")}px;
  margin-top: ${(props) => (props.isMobile ? "5" : "10")}px;

  padding-top: ${(props) => (props.isMobile ? "2" : "50")}px;
  padding-left: ${(props) => (props.isMobile ? "2" : "50")}px;
`;

function ResetPassword() {
  const isMobile = useMediaQuery({ query: "(max-width: 500px)" });
  //const changeAuthMode = () => {navigate("/user/register");};
  const navigate = useNavigate();
  //const { setUser } = useAuth();

  const [error, SetError] = useState(undefined);
  const [modalTitle, SetModalTitle] = useState(undefined);
  const [modalType, SetModalType] = useState(undefined);

  const [code, setCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordAgain, setNewPasswordAgain] = useState("");

  //const [resetPasswordSuccess, setResetPasswordSuccess] = useState(false);

  const { isShowing: isShowingError, toggle: toggleErrorModal } = useModal();

  //const { isShowing: isSessionError, toggle: toggleSessionModal } = useModal();

  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordAgain, setShowPasswordAgain] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  /* useEffect(() => {
    logout().then((response) => {});
  }, []);
 */

  function handleSubmit(event) {
    setIsLoading(true);
    //console.log("isloading:", isLoading);
    if (newPassword) {
      const passwordPattern =
        /^(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?])[A-Za-z\d@$!%*#?]{8,}$/;

      /**
       *  En az bir küçük harf içermesi (a-z arasında).
          En az bir rakam içermesi (0-9 arasında).
          Belirli özel karakterlerden en az bir tanesini içermesi (@, $, !, %, *, #, ?).
          Toplamda en az 8 karakter uzunluğunda olması.
       */
      if (!passwordPattern.test(newPassword)) {
        SetModalType(false);
        SetModalTitle("Şifre Hatası");
        SetError("Parolanız belirtilen koşullara uygun olmalıdır.");
        toggleErrorModal();
        setIsLoading(false);
      } else {
        //console.log("isloading:", isLoading);
        resetPasswordV2(code, newPassword, newPasswordAgain)
          .then((response) => {
            //console.log("response:", response);
            if (
              response !== undefined &&
              response.data.code !== undefined &&
              response.data.code === 200
            ) {
              SetModalType(true);
              SetModalTitle(response.data.message);
              SetError("Giriş sayfasına yönlendiriliyorsunuz.");
              toggleErrorModal();
              setTimeout(() => {
                navigate("/user/login");
              }, 3000);
              //if (response.data.data.token !== undefined) {
              //  navigate("/home");
              //}
            } else {
              SetModalType(false);
              if (response !== undefined && response.data.code !== undefined) {
                SetError(response.data.message);
                SetModalTitle("Bir Sorun Oluştu");
              } else {
                SetError(response.data.message);
                SetModalTitle("Bir Sorun Oluştu!");
              }
              toggleErrorModal();
              setIsLoading(false);
            }
          })
          .catch((err) => {
            SetError("Beklenmeyen bir hata oluştu.");
            toggleErrorModal();
            setIsLoading(false);
          });
      }
    }

    event.preventDefault();
    //console.log("isloading:", isLoading);
  }

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleShowPasswordAgain = () => {
    setShowPasswordAgain(!showPasswordAgain);
  };

  return (
    <>
      <div className="application">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Şifre Sıfırlama</title>
          <meta name="description" content="Hoox hesabındaki şifreni yenile." />
        </Helmet>
      </div>
      <div
        style={{
          height: "100%",
          //backgroundSize: "auto 860px",
          //backgroundImage:
          //  "url('https://imagedelivery.net/qoBmvbtK9_6g_NBy8qZRPw/a16eb849-415b-4d5b-61b6-1c6ee8b29100/public')",
          //backgroundRepeat: "no-repeat" /* Tekrar etme */,
          //backgroundPosition: "right",
        }}
      >
        <div
          className="container"
          style={{
            width: "75%",
          }}
        >
          <div
            className="row d-flex justify-content-center justify-content-md-start"
            style={{ paddingTop: isMobile ? "80px" : "140px" }}
          >
            <div
              className="col-6"
              style={{
                backgroundColor: "rgba(255, 255, 255, 0.25)",
                paddingBottom: "20px",
                borderRadius: "15px",
                minWidth: "300px",
                maxWidth: "450px",
                color: "#fff",
              }}
            >
              <Row style={{ margin: "10px", color: "#fff" }}>
                <form onSubmit={handleSubmit}>
                  <div
                    className="d-flex justify-content-center"
                    style={{ margin: 10 }}
                  >
                    <div
                      style={{
                        fontSize: "23px",
                        fontWeight: "500",
                      }}
                    >
                      ŞİFRE SIFIRLAMA
                    </div>
                  </div>
                  <hr
                    style={{
                      color: "#12d6df",
                      opacity: "1",
                      margin: "15px 0 35px",
                    }}
                  />

                  {/** General Error Popup */}
                  <HooxModal
                    isShowing={isShowingError}
                    hide={toggleErrorModal}
                    title={modalTitle}
                    type={modalType ? HOOX_MODAL.INFO : HOOX_MODAL.ERROR}
                  >
                    <Row style={{ margin: "10px", textAlign: "center" }}>
                      <Col style={{ margin: "10px", textAlign: "center" }}>
                        {error}
                      </Col>
                    </Row>
                    <Row>
                      <Col style={{ textAlign: "center" }}>
                        <Button
                          variant="secondary"
                          onClick={toggleErrorModal}
                          style={{ width: "100px" }}
                        >
                          Kapat
                        </Button>
                      </Col>
                    </Row>
                  </HooxModal>

                  <Col sm="12">
                    <div
                      style={{
                        marginBottom: "5px",
                        fontSize: "13px",
                      }}
                    >
                      ŞİFRE SIFIRLAMA KODU
                    </div>
                    <FormControl
                      value={code}
                      onChange={(e) => setCode(e.target.value)}
                      type="text"
                      id="code"
                      placeholder="Kod"
                      required
                    />
                  </Col>

                  <Col sm="12" style={{ marginTop: "20px" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          marginBottom: "5px",
                          fontSize: "13px",
                        }}
                      >
                        YENİ ŞİFRE
                      </div>
                    </div>

                    <div style={{ position: "relative" }}>
                      <FormControl
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        type={showPassword ? "text" : "password"}
                        placeholder="Yeni Şifre"
                        required
                      />

                      {showPassword ? (
                        <ShowPasswordIcon
                          className="bi bi-eye"
                          onClick={handleShowPassword}
                        ></ShowPasswordIcon>
                      ) : (
                        <ShowPasswordIcon
                          className="bi bi-eye-slash"
                          onClick={handleShowPassword}
                        ></ShowPasswordIcon>
                      )}
                    </div>
                  </Col>

                  <Col sm="12" style={{ marginTop: "20px" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          marginBottom: "5px",
                          fontSize: "13px",
                        }}
                      >
                        YENİ ŞİFRE TEKRAR
                      </div>
                    </div>

                    <div style={{ position: "relative" }}>
                      <FormControl
                        value={newPasswordAgain}
                        onChange={(e) => setNewPasswordAgain(e.target.value)}
                        type={showPasswordAgain ? "text" : "password"}
                        placeholder="Yeni Şifre Tekrar"
                        required
                      />

                      {showPasswordAgain ? (
                        <ShowPasswordIcon
                          className="bi bi-eye"
                          onClick={handleShowPasswordAgain}
                        ></ShowPasswordIcon>
                      ) : (
                        <ShowPasswordIcon
                          className="bi bi-eye-slash"
                          onClick={handleShowPasswordAgain}
                        ></ShowPasswordIcon>
                      )}
                    </div>
                  </Col>

                  <Col sm="12">
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Button
                        style={{
                          marginTop: "20px",
                          width: "50%",
                          fontSize: "12px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                        className="btn btn-primary checkOutButton w-100"
                        type="submit"
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          <div className="spinner"></div>
                        ) : (
                          "SİFRE YENİLE"
                        )}
                      </Button>
                    </div>
                  </Col>
                </form>
              </Row>
            </div>

            <div className="col-md-6 col-xs-12">
              <InfoText isMobile={isMobile}>
                <p>
                  Email adresinize gönderdiğimiz sıfırlama kodu ile birlikte
                  yeni şifrenizi tanımlayabilirsiniz.
                </p>
                <ul style={{ fontSize: "15px", color: "#aaaaaaf7" }}>
                  <li>En az bir küçük harf içermesi (a-z arasında).</li>
                  <li>En az bir rakam içermesi (0-9 arasında).</li>
                  <li>
                    Belirli özel karakterlerden en az bir tanesini içermesi
                    <br />
                    (@, $, !, %, *, #, ?).
                  </li>
                  <li>Toplamda en az 8 karakter uzunluğunda olması. </li>
                </ul>
              </InfoText>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ResetPassword;
