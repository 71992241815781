import { useEffect } from "react";

let temp = 0;

function VideoStatusUsingApi({ videoRef, isAPIReady, setPlayer }) {
  useEffect(() => {
    if (!isAPIReady) return;
    if (!videoRef) return;

    const player = new window.VdoPlayer(videoRef);
    window.player1 = player;

    // Video muted özelliğini ayarla
    //player.video.defaultMuted = true;

    player.video.addEventListener("timeupdate", () => {
      //console.log("progress", player.video.currentTime);// izlenen saniyeyi basıyor surekli
      //player.api
      //  .getTotalPlayed()
      //  .then((data) => console.log("Total Video getTotalPlayed: ", data));
      if (temp === 0) {
        player.video.currentTime = 0;
        temp = temp + 1;
      }
    });
    player.video.addEventListener("ended", () => {
      player.video.pause();
      player.video.currentTime = 0;
    });

    player.video.addEventListener("play", () => {
      player.video.muted = true;
    });

    player.video.addEventListener("progress", () => {
      //console.log("progress", player.video.currentTime);
    });

    setPlayer(player);

    return () => {
      // Event listener'ları temizle
      player.video.removeEventListener("timeupdate", () => {});
      player.video.removeEventListener("ended", () => {});
      player.video.removeEventListener("play", () => {});
    };

    //window.player1 = player;
  }, [isAPIReady, videoRef]);

  return null;
}

export default VideoStatusUsingApi;
