import Axios from "../util/apiConfig";

Axios.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("authToken");
  config.headers.Authorization = token;
  return config;
});

export async function sendTracking(pageUrl) {
  return Axios.get("/aut/track/web", {
    params: {
      url: pageUrl,
    },
  })
    .then((result) => result.data)
    .catch((error) => {
      return error;
    });
}

export async function getNewToken() {
  return Axios.get("/aut/auth/gnt")
    .then((result) => result.data)
    .catch((error) => {
      return error;
    });
}
