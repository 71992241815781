import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import useModal from "../../components/hooks/useModal";
import HooxModal, { HOOX_MODAL } from "../../components/HooxModal";
import { getProducts } from "../../service/contentService";
import {
  PaymentInfo,
  ProductBox,
  ProductDescription,
  ProductPageTitle,
  ProductPrice,
  ProductTitle,
} from "./ProductSelection.styles";
import { Helmet } from "react-helmet";
import { verifySubscription } from "../../service/customerService";
import MatomoTracking from "../../components/MatomoTracking";

const ProductSelection = () => {
  const [product, setProduct] = useState(null);
  const [products, setProducts] = useState([]);

  const [activeIndex, setActiveIndex] = useState(null);
  const [isEduUrl, setIsEduUrl] = useState(false);

  const { isShowing: isShowingSuccess, toggle: toggleSuccessModal } =
    useModal();

  const { isShowing: isShowingFail, toggle: toggleFailModal } = useModal();

  const {
    isShowing: isShowingPackageSelection,
    toggle: togglePackageSelectionModal,
  } = useModal();

  let { guid: customerGuid } = useParams();

  const queryParams = new URLSearchParams(window.location.search);

  // Belirli bir query parametresini al
  const educationPackage = queryParams.get("epi");

  const [eduProduct, setEduProduct] = useState(null);
  const [eduProductIndex, setEduProductIndex] = useState(null);

  const [failMessage, setFailMessage] = useState(null);

  useEffect(() => {
    getProducts().then((response) => {
      setProducts(response.data);
      // TODO: select activeIndex logic
    });
  }, []);

  useEffect(() => {
    if (educationPackage === "edu") {
      setIsEduUrl(true);
      //setActiveIndex(2);
    }
  }, [isEduUrl]);

  useEffect(() => {
    // Ürünler geldikten sonra belirli bir koşulu kontrol etme
    if (products.length > 0) {
      const selectedProduct = products.find(
        (product) => product.externalId === "edu"
      );
      setEduProduct(selectedProduct);
      setEduProductIndex(products.indexOf(selectedProduct));

      if (selectedProduct && educationPackage === "edu") {
        setProduct(selectedProduct); // Seçili ürünü state'e kaydet
        setActiveIndex(products.indexOf(selectedProduct));
      } else {
        //console.warn("Belirli koşula uyan ürün bulunamadı.");
      }
    }
  }, [products]);

  const handleProductSelection = (productToSet, index) => () => {
    if (eduProductIndex >= 0) {
      if (isEduUrl) {
        setProduct(eduProduct);
        setActiveIndex(eduProductIndex);
        return;
      } else if (productToSet.externalId === "edu") {
        return;
      }
    }
    setProduct(productToSet);
    setActiveIndex(index);
    //console.log("select:", productToSet);
  };

  const navigation = useNavigate();

  const handleSubmit = async () => {
    // add verify Subscription
    if (product && product?.externalId) {
      verifySubscription(product.externalId, customerGuid).then((response) => {
        //console.log("response:", response);
        if (response?.code === 200) {
          navigation(
            "/payment-direct/" + customerGuid + "/" + product.externalId
          );
        } else {
          setFailMessage(response?.message);
          toggleFailModal();
        }
      });
    } else {
      togglePackageSelectionModal();
    }
  };

  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '">' + (index + 1) + "</span>";
    },
  };

  return (
    <>
      <MatomoTracking />
      <div className="application">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Paket Seçimi</title>
        </Helmet>
      </div>

      <Container>
        <Row>
          <Col>
            <ProductPageTitle className="text-center m-4">
              PAKETLER
            </ProductPageTitle>
          </Col>
        </Row>
        <Row>
          <Col>
            <Container>
              <Row className="justify-content-center mt-3">
                {products &&
                  products.slice(0, 3).map((product, index) => (
                    <Col
                      md={3}
                      onClick={handleProductSelection(product, index)}
                      key={index}
                    >
                      <ProductBox
                        key={index}
                        className="text-center p-4 mb-5"
                        isActive={activeIndex === index}
                      >
                        <div className="m-4">
                          <img
                            style={{ height: "auto", width: "40%" }}
                            src={product.imageUrl}
                          />
                        </div>
                        <ProductTitle>{product.name}</ProductTitle>
                        <hr
                          style={{
                            color: "#12d6df",
                            opacity: "1",
                            margin: "20px",
                          }}
                        />
                        <ProductDescription
                          style={{ textAlign: "left", margin: "20px" }}
                        >
                          {product.description.split("|").map((item, index) => (
                            <p key={index} style={{ margin: "2px" }}>
                              <span style={{ color: "#bc00bc" }}>• </span>
                              {`${item}`}
                            </p>
                          ))}
                        </ProductDescription>
                        <Row style={{ minHeight: "25px" }}>
                          {
                            // eudcaitonPackage kontrolü incelenecek. gerek olmayabilir.
                            product?.id === 4 ? (
                              <div
                                style={{
                                  fontSize: "12px",
                                  fontStyle: "italic",
                                  margin: "1px 3px 1px 3px",
                                  color: "#cccacad1",
                                }}
                                className="text-center"
                              >
                                7 gün 7 TL
                              </div>
                            ) : (
                              ""
                            )
                          }

                          {
                            // eudcaitonPackage kontrolü incelenecek. gerek olmayabilir.
                            educationPackage === "edu" ? (
                              <div
                                style={{
                                  fontSize: "12px",
                                  fontStyle: "italic",
                                  margin: "1px 3px 1px 3px",
                                  color: "#cccacad1",
                                }}
                                className="text-center"
                              >
                                *edu ile biten eposta adresiyle seçilebilir.
                              </div>
                            ) : product?.externalId == "edu" ? (
                              <div
                                style={{
                                  fontSize: "12px",
                                  fontStyle: "italic",
                                  margin: "1px 3px 1px 3px",
                                  color: "#cccacad1",
                                }}
                                className="text-center"
                              >
                                *edu ile biten eposta adresiyle seçilebilir.
                              </div>
                            ) : (
                              " "
                            )
                          }
                        </Row>
                        <Row>
                          <ProductPrice>
                            {
                              product.priceWithTax
                                .toLocaleString("tr-TR", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                                .split(",")[0]
                            }
                            ,
                            <small>
                              {
                                product.priceWithTax
                                  .toLocaleString("tr-TR", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })
                                  .split(",")[1]
                              }
                            </small>{" "}
                            ₺
                          </ProductPrice>
                        </Row>
                      </ProductBox>
                    </Col>
                  ))}
              </Row>
            </Container>
          </Col>
        </Row>
        <Row>
          <Col className="text-center mt-3">
            <PaymentInfo>
              **Aylık Plan abonelik yaptırdığınızda iptal etmediğiniz sürece
              otomatik yenilenir.
            </PaymentInfo>
          </Col>
        </Row>
        <Row>
          <Col className="text-center mt-3">
            {customerGuid && (
              <Button
                style={{ color: "#fff" }}
                className="btn btn-primary checkOutButton"
                type="submit"
                onClick={handleSubmit}
              >
                ÖDEME YAP
              </Button>
            )}
            <HooxModal
              isShowing={isShowingSuccess}
              hide={toggleSuccessModal}
              title="İşlem Başarısız."
              type={HOOX_MODAL.INFO}
            >
              <p style={{ color: "#ffffff" }}>
                Aktif Aboneliğiniz Bulunmaktadır.
              </p>
            </HooxModal>

            <HooxModal
              isShowing={isShowingPackageSelection}
              hide={togglePackageSelectionModal}
              title="Paket Seçimi"
              type={HOOX_MODAL.INFO}
            >
              <p style={{ color: "#ffffff" }}>
                İlerlemek için bir paket seçmelisiniz.
              </p>
            </HooxModal>

            <HooxModal
              isShowing={isShowingFail}
              hide={toggleFailModal}
              title="İşlem Başarısız."
              type={HOOX_MODAL.INFO}
            >
              <p style={{ color: "#ffffff" }}>{failMessage}</p>
            </HooxModal>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ProductSelection;
