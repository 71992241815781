import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper";

import { Navigation } from "swiper";

import "swiper/css";
import "swiper/css/navigation";

SwiperCore.use([Autoplay, Navigation]);

const HooxSwiper = (props) => {
  const swiperRef = useRef(null);

  // Swiper ayarları
  const swiperParams = {
    modules: [Navigation],
    spaceBetween: 0,
    slidesPerView: 2.2,
    loop: true, // Sürekli akar şekilde dönmesini sağlar
    autoplay: {
      delay: 2000, // Otomatik oynatma süresi (ms cinsinden)
      disableOnInteraction: false, // Kullanıcı etkileşimiyle autoplay durmasın
      waitForTransition: false, // Otomatik oynatma arasında geçiş bekleme
    },
    speed: 1000, // Otomatik kayma hızı (ms cinsinden)
    breakpoints: {
      768: {
        slidesPerView: 2.2,
        spaceBetween: 0,
      },
      1024: {
        slidesPerView: 3.5,
        spaceBetween: 0,
      },
    },
  };

  return (
    <Swiper
      ref={swiperRef}
      {...swiperParams}
      style={{ height: props.isMobile ? "135px" : "270px" }}
    >
      {props.contents?.map((content, index) => (
        <SwiperSlide
          key={index}
          style={{
            maxWidth: "480px",
          }}
        >
          <Link to={`/${content.urlName}/${content.guid}`}>
            <img
              className="bd-placeholder-img"
              style={{ height: "auto", width: "100%" }}
              src={content.listPosterUrl}
              alt={content.urlName}
            />
          </Link>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default HooxSwiper;
