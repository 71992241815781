import React from "react";
import "./SubscribeButton.css";
import { Button as BootstrapButton } from "react-bootstrap";
import { useNavigate } from "react-router";

const SubscribeButton = () => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        marginLeft: "15px",
      }}
    >
      <BootstrapButton
        style={{ marginBottom: "15px", width: "50%" }}
        className="btn btn-primary checkOutButton w-100"
        type="button"
        onClick={() => {
          navigate("/abonelik/paketler");
        }}
      >
        ABONE OL
      </BootstrapButton>
    </div>
  );
};

export default SubscribeButton;
