import React from "react";
import CookieConsent from "react-cookie-consent";
import { initGoogleAnalytic } from "../service/googleAnalyticsService";

const COOKIE_TEXT = {
  text: "Web sitemizde çerezler kullanıyoruz. Çerezler, size daha iyi bir kullanıcı deneyimi sunmak ve web sitemizi geliştirmek için kullanılır.",
  accept: "Kabul Et",
  decline: "İptal",
};

function CookieModal() {
  const onAcceptCookie = () => initGoogleAnalytic();

  return (
    <CookieConsent
      location="bottom"
      buttonText={COOKIE_TEXT.accept}
      enableDeclineButton={true}
      onAccept={onAcceptCookie}
      declineButtonText={COOKIE_TEXT.decline}
      declineButtonStyle={{
        backgroundColor: "transparent",
      }}
      style={{ backgroundColor: "#6f42c18f", color: "#f9f7f7a8" }}
      buttonStyle={{
        background: "#bc00bc",
        color: "#fff",
        borderRadius: "5px",
      }}
    >
      <div>{COOKIE_TEXT.text}</div>
    </CookieConsent>
  );
}

export default CookieModal;
