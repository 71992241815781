import { useMediaQuery } from "react-responsive";
import "./welcome/styles.css";
import { useNavigate, useParams } from "react-router-dom";
import { iysRed } from "../service/customerService";
import { useEffect, useState } from "react";

function IysRed() {
  const guid = useParams().guid;
  const navigate = useNavigate();
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 630px)",
  });

  const [message, setMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    //console.log("guid:" + guid);

    iysRed(guid)
      .then((response) => {
        //console.log("iysRed.response.data:", response.data);
        setMessage(response.data.message);
        if (response.data.code == 200) {
          setIsSuccess(true);
        }
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <>
      <div className="text-container">
        <div style={{ margin: "10px" }}>
          <img src="/assets/img/hoox-logo.png"></img>
        </div>
        {isSuccess && (
          <span
            className=""
            style={{ width: "100%", fontSize: "1.8em", margin: "20px" }}
          >
            Mail listemizden ayrıldın. Seni Özleyeceğiz.
          </span>
        )}
        <p style={{ alignSelf: "center", fontSize: "1.4em", margin: "10px" }}>
          {message}
        </p>
      </div>
    </>
  );
}

export default IysRed;
