import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import RelatedContents from '../components/ContentList';

import {
  getLabelsByCategory,
  getContentByLabel,
  Search,
  getSearchSuggest,
} from '../service/contentService';

import EventComponent from '../components/EventComponent';

function Listing() {
  const { labelUrl } = useParams();
  const { categoryId } = useParams();
  const [categoryIdValue] = useState(categoryId);
  const [contentList, setContentList] = useState(undefined);
  const [suggestList, setSuggestList] = useState(undefined);
  const [label, setLabel] = useState(undefined);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (categoryId !== undefined) {
      getLabelsByCategory(categoryId)
        .then((response) => {
          setLabel(response.data.find((x) => x.urlName === labelUrl));
        })
        .catch((error) => console.log(error));
    }
  }, [categoryId, labelUrl, categoryIdValue]);
  useEffect(() => {}, [location]);

  useEffect(() => {
    if (suggestList === undefined) {
      getSearchSuggest().then((response) => {
        setSuggestList(response.data);
      });
    }
  });

  useEffect(() => {
    if (label !== undefined) {
      getContentByLabel(label.id)
        .then((response) => {
          setContentList(response);
        })
    }
  }, [label]);

  useEffect(() => {
    Search(location.state?.search).then((response) => {
      setContentList(response);
    });

    return () => {
      location.state = null;
    }
  }, [location]);

  return (
    <div className="search">
      <div className="container">
        <div className="content" style={{ minHeight: '500px' }}>
          <div className="row">
            {contentList !== undefined && contentList.data.length !== 0 ? (
              contentList.data.map((item, index) => (
                <div className="col-12 col-xl-4 col-lg-4 col-sm-6 mt-4">
                  <EventComponent
                    history={navigate}
                    item={item}
                    listType={1}
                  />
                </div>
              ))
            ) : suggestList !== undefined && suggestList.length !== 0 ? (
              <RelatedContents relatedContents={suggestList} />
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
export default Listing;
