import { Swiper, SwiperSlide } from "swiper/react";
import EventComponent from "./EventComponent";

import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";

import { Navigation } from "swiper";

import "swiper/css";
import "swiper/css/navigation";

//SwiperCore.use([Navigation]);

const NumericSwiperSlide = styled(SwiperSlide)`
  position: relative;

  &:before {
    content: attr(data-order);
    display: block;
    position: absolute;
    font-size: ${(props) => props.$isBig && "220px"};
    font-size: ${(props) => props.$isMiddle && "150px"};
    font-size: ${(props) => props.$isMiddle && props.$isSmall && "90px"};
    font-size: ${(props) =>
      props.$isMiddle && props.$isSmall && props.$isMobile && ""};
    top: -15%;
    right: 95%;
    color: #fff;
    z-index: -1;
    font-family: "GilroyBold";
  }
`;

const SwiperContainer = styled(Swiper)`
  & .swiper-button-prev {
    left: 0;
  }

  & .swiper-button-next {
    right: 0;
  }

  & .swiper-button-disabled {
    display: none;
  }

  & .swiper-button-prev,
  & .swiper-button-next {
    color: #fff;
    opacity: 1;
    background-color: rgba(55, 55, 55, 0.5);
    width: 45px;
    height: auto;
    min-height: 100%;
    padding: 0;
    margin: 0;
    top: 0;

    &:hover {
      background-color: rgba(55, 55, 55, 0.8);
    }
  }
`;

function ContentList({
  contents,
  title,
  slidePerView = 3.8,
  spaceBetween = 20,
  showOrderNumber,
}) {
  const isMobile = useMediaQuery({ query: "(max-width: 850px)" });
  const isSmall = useMediaQuery({ query: "(max-width: 1150px)" });
  const isMiddle = useMediaQuery({ query: "(max-width: 1500px)" });
  const isBig = useMediaQuery({ query: "(min-width: 1500px)" });

  return contents ? (
    <Container fluid className="mt-5 p-0">
      <Row className="d-flex justify-content-between">
        <Col className={isMobile ? "col-12" : "col-6"}>
          <p className={isMobile ? "label-title-mobile" : "label-title"}>
            {title}
          </p>
        </Col>
      </Row>
      <SwiperContainer
        breakpoints={{
          300: {
            slidesPerView: 1,
            spaceBetween: 15,
          },
          500: {
            slidesPerView: 2,
            spaceBetween: 15,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 18,
          },
          1024: {
            slidesPerView: slidePerView,
            spaceBetween: spaceBetween,
          },
        }}
        slidesPerView={slidePerView}
        spaceBetween={isMobile ? "10px" : spaceBetween}
        style={{
          paddingLeft: showOrderNumber && !isMobile ? "80px" : "",
          paddingRight: !isMobile ? 100 : 0,
        }}
        navigation
      >
        {contents.map((content, index) => {
          if (showOrderNumber && !isMobile) {
            return (
              <NumericSwiperSlide
                key={index}
                data-order={index + 1}
                $isSmall={isSmall}
                $isBig={isBig}
                $isMiddle={isMiddle}
                style={{
                  marginLeft: index === 0 ? (isMobile ? "10px" : "90px") : "",
                  maxWidth: "480px",
                }}
              >
                <EventComponent item={content} shadow={true} />
              </NumericSwiperSlide>
            );
          } else {
            return (
              <SwiperSlide
                key={index}
                style={{
                  marginLeft: index === 0 ? (isMobile ? "10px" : "90px") : "",
                  maxWidth: "480px",
                }}
              >
                <EventComponent item={content} />
              </SwiperSlide>
            );
          }
        })}
      </SwiperContainer>
    </Container>
  ) : null;
}

export default ContentList;
