import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import "./welcome/styles.css";
import DiscoverButton from "../components/DiscoverButton";
import { Carousel, Row } from "react-bootstrap";

import {
  getLandingPageBAnners,
  getLandingPageContents,
} from "../service/contentService";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import MatomoTracking from "../components/MatomoTracking";
import { useAuth } from "../components/AuthContext";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import HooxSwiper from "../components/HooxSwiper";
import HooxSwiperText from "../components/HooxSwiperText";

const CarouselStyled = styled(Carousel)`
  & .carousel-control-prev,
  & .carousel-control-next {
    justify-content: flex-start;
    height: 40%;
    margin: auto;
    width: auto;
    background-color: rgba(55, 55, 55, 0.5);
    opacity: 1;
  }

  & .carousel-control-prev:hover,
  & .carousel-control-next:hover {
    background-color: rgba(55, 55, 55, 0.8);
  }

  & .carousel-control-next-icon,
  & .carousel-control-prev-icon {
    color: #fff;
  }

  & .carousel-indicators {
    margin-bottom: ${(props) => (props.ismobile ? "0px" : null)};
    button {
      width: ${(props) => (props.ismobile ? "10px" : "15px")};
      height: ${(props) => (props.ismobile ? "10px" : "15px")};
      border-radius: 50%;
    }

    .active {
      background-color: #bb00bb;
    }
  }
`;

const SwiperContainer = styled(Swiper)`
  & .swiper-button-prev {
    left: 0;
  }

  & .swiper-button-next {
    right: 0;
  }

  & .swiper-button-disabled {
    display: none;
  }

  & .swiper-button-prev,
  & .swiper-button-next {
    color: #fff;
    opacity: 1;
    background-color: rgba(55, 55, 55, 0.5);
    width: 45px;
    height: auto;
    min-height: 100%;
    padding: 0;
    margin: 0;
    top: 0;

    &:hover {
      background-color: rgba(55, 55, 55, 0.8);
    }
  }
`;

function LandingPage(slidePerView = 3.8, spaceBetween = 20) {
  const navigate = useNavigate();

  //const [products, setProducts] = useState(null);
  const [banners, setBanners] = useState(null);
  const [contents, setContents] = useState(null);
  //const isDesktopOrLaptop = useMediaQuery({query: "(min-width: 630px)",});

  const isMobile = useMediaQuery({ query: "(max-width: 1000px)" });
  const isMiddle = useMediaQuery({ query: "(min-width: 1600px)" });
  const isSmall = useMediaQuery({
    query: "(min-width: 1200px) and (max-width: 1599px)",
  });

  const widthPercentage = isMiddle
    ? "30%" // isMiddle true ise %35
    : isSmall
    ? "35%" // isSmall true ise %60
    : "62%"; // Her ikisi de false ise %80

  //const fontSizeValues = isMiddle ? "79px" : isSmall ? "38px" : "52px";

  //const fontSizeSecondValues = isMiddle ? "29px" : isSmall ? "18px" : "22px";

  //const lineHeightValues = isMiddle ? "72px" : isSmall ? "40px" : "50px";

  const windowWidth = window.innerWidth;
  const { user } = useAuth();

  useEffect(() => {
    getLandingPageBAnners().then((response) => {
      //console.log("landing page banners response :", response);
      if (response.code === 200) {
        setBanners(response.data);
      }
    });
  }, []);

  useEffect(() => {
    getLandingPageContents().then((response) => {
      //console.log("landing page contents response :", response);
      if (response.code === 200) {
        setContents(response.data);
      }
    });
  }, []);

  useEffect(() => {
    document.body.style.marginTop = 0;

    return () => {
      document.body.style.marginTop = null;
    };
  }, []);

  //console.log("user:", user);

  return (
    <>
      <MatomoTracking />
      <div className="application">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Hoox</title>
          <meta
            name="description"
            content="Muud medya dijital yayıncılığın Rtük onaylı tescilli markası olan Hoox.tv yeni nesil bir dijital video izleme platformdur. Keşfetmekten keyif alınacak..."
          />
        </Helmet>
      </div>

      <div
        className="welcome"
        // style={{ backgroundImage: `url(/assets/img/background.png)` }}
      >
        <CarouselStyled
          controls={banners && banners.length > 1}
          ismobile={isMobile ? isMobile : undefined}
          style={{
            background: `url(/assets/img/ooimgv3.png)`,
            backgroundSize: "auto 100%",
          }}
        >
          {banners &&
            banners.map((banner) => (
              <Carousel.Item key={banner.id}>
                <Link to={`/${banner.urlName}/${banner.guid}`}>
                  <img
                    className="bd-placeholder-img"
                    style={{ height: "auto", width: "100%" }}
                    src={banner.landingPosterUrl}
                    alt=""
                  />
                </Link>
              </Carousel.Item>
            ))}
        </CarouselStyled>

        {isMobile ? (
          <div>
            <div className="text-container" style={{ width: "100%" }}>
              <h1 style={{ fontSize: "28px", lineHeight: "31px" }}>
                İzlenecek
              </h1>
              <h1 style={{ fontSize: "28px", lineHeight: "21px" }}>
                çok şey var!
              </h1>
              <span
                style={{
                  marginTop: "20px",
                  fontSize: "18px",
                  lineHeight: "24px",
                  width: "90%",
                }}
              >
                İlgi çekici ve ilham veren içeriklerle dolu bir dünyanın
                kapılarını aralamak için tam olman gereken yerdesin. Stand-up’
                tan tiyatroya, yemekten geziye, astrolojiden sohbete ne varsa
                burada. Hayata dair izlenecek çok şey var!
              </span>
            </div>

            <div className="text-container" style={{ marginTop: "60px" }}>
              <h1 style={{ fontSize: "24px", lineHeight: "31px" }}>
                HOŞ GELDİN KAYITSIZ KALMA!
              </h1>
              <span
                style={{
                  width: "95%",
                }}
              >
                Hesap oluşturarak ücretsiz gir, gez, izle sonra sana uygun
                paketi seç.
              </span>
              {user?.isLoggedIn == true ? (
                ""
              ) : (
                <div>
                  <DiscoverButton
                    showHoox={false}
                    onButtonClick={() => navigate("/user/register")}
                    style={{}}
                    isMobile={isMobile}
                    text={"KAYIT OL"}
                  />
                </div>
              )}

              {/*
              <div>
                <DiscoverButton
                  showHoox={false}
                  //onButtonClick={() => navigate("/user/register")}
                  onButtonClick={() => navigate("/home")}
                  style={{}}
                  isMobile={isMobile}
                  text={"KEŞFET"}
                />
              </div>
            */}
            </div>
          </div>
        ) : (
          <div className="filler">
            <div className="text-container" style={{ width: widthPercentage }}>
              <h1
                style={{
                  fontSize: isMiddle ? "79px" : isSmall ? "66px" : "46px",
                  lineHeight: isMiddle ? "72px" : isSmall ? "66px" : "36px",
                }}
              >
                İzlenecek
              </h1>
              <h1
                style={{
                  fontSize: isMiddle ? "79px" : isSmall ? "66px" : "40px",
                  lineHeight: isMiddle ? "72px" : isSmall ? "66px" : "36px",
                }}
              >
                çok şey var!
              </h1>
              <span
                style={{
                  marginTop: isMiddle ? "50px" : "30px",
                  fontSize: "18px",
                  lineHeight: "24px",
                }}
              >
                İlgi çekici ve ilham veren içeriklerle dolu bir dünyanın
                kapılarını aralamak için tam olman gereken yerdesin. Stand-up’
                tan tiyatroya, yemekten geziye, astrolojiden sohbete ne varsa
                burada. Hayata dair izlenecek çok şey var!
              </span>
            </div>

            <div
              className="text-container"
              style={{
                marginTop: isMiddle ? "90px" : isSmall ? "80px" : "60px",
                width: widthPercentage,
              }}
            >
              <h1
                style={{
                  fontSize: isMiddle ? "29px" : isSmall ? "22px" : "20px",
                }}
              >
                HOŞ GELDİN KAYITSIZ KALMA!
              </h1>
              <span
                style={{
                  marginTop: "0px",
                  fontSize: "16px",
                }}
              >
                Hesap oluşturarak ücretsiz gir, gez, izle sonra sana uygun
                paketi seç.
              </span>
              {user?.isLoggedIn == true ? (
                ""
              ) : (
                <div id="explore-button">
                  <DiscoverButton
                    showHoox={false}
                    onButtonClick={() => navigate("/user/register")}
                    //onButtonClick={() => navigate("/home")}
                    style={{
                      left: windowWidth / 10.5,
                      top: windowWidth / 3.7,
                    }}
                    text={"KAYIT OL"}
                  />
                </div>
              )}

              {/**
              <div id="explore-button">
                <DiscoverButton
                  showHoox={false}
                  //onButtonClick={() => navigate("/user/register")}
                  onButtonClick={() => navigate("/home")}
                  style={{
                    left: windowWidth / 10.5,
                    top: windowWidth / 3.7,
                  }}
                  text={"KEŞFET"}
                />
              </div>
 */}
            </div>
          </div>
        )}
      </div>

      <Row>
        <div
          className="text-container"
          style={{ marginBottom: "2rem", marginTop: "6rem" }}
        >
          <h1
            style={{ fontSize: isMiddle ? "53px" : isSmall ? "42px" : "28px" }}
          >
            AZ ZAMANDA ÇOK İÇERİK, ÇOK EĞLENCE…
          </h1>
        </div>
      </Row>

      <div style={{ touchAction: "pan-x" }}>
        <HooxSwiper contents={contents} isMobile={isMobile}></HooxSwiper>
      </div>

      {/* 
      <Row>
        <SwiperContainer
          breakpoints={{
            300: {
              slidesPerView: 1,
              spaceBetween: 1,
            },
            500: {
              slidesPerView: 2,
              spaceBetween: 1,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 1,
            },
            1024: {
              slidesPerView: 3.8,
              spaceBetween: 1,
            },
          }}
          modules={[Navigation]}
          slidesPerView={slidePerView}
          spaceBetween="1px"
          navigation
        >
          {contents?.map((content, index) => {
            return (
              <SwiperSlide
                key={index}
                style={{
                  maxWidth: "480px",
                }}
              >
                <Link to={`/${content.urlName}/${content.guid}`}>
                  <img
                    className="bd-placeholder-img"
                    style={{ height: "auto", width: "100%" }}
                    src={content.listPosterUrl}
                    alt={content.urlName}
                  />
                </Link>
              </SwiperSlide>
            );
          })}
        </SwiperContainer>
      </Row>
*/}

      <Row>
        <HooxSwiperText />
      </Row>

      <Row>
        <div className="text-center">
          <img
            style={{
              width: isMobile ? "80%" : "50%",
              marginTop: isMobile ? "50px" : "220px",
              marginBottom: "30px",
            }}
            src={
              "https://imagedelivery.net/qoBmvbtK9_6g_NBy8qZRPw/e00feade-4a70-4ae5-718d-231b954c7700/public"
            }
            alt=""
          />
        </div>
      </Row>

      <div className="text-container">
        <h1
          style={{
            fontSize: isMobile
              ? "20px"
              : isMiddle
              ? "57px"
              : isSmall
              ? "42px"
              : "32px",
          }}
        >
          Dilediğin yerde, istediğin zaman izle!
        </h1>
        <span
          style={{
            fontSize: isMobile ? "18px" : "20px",
            lineHeight: isMobile ? "24px" : "32px",
            fontWeight: "300",
            width: isMobile
              ? "80%"
              : isMiddle
              ? "30%"
              : isSmall
              ? "50%"
              : "50%",
            marginBottom: "50px",
          }}
        >
          İstediğin an, istediğin yerde hoox.tv’nin orijinal ve eğlenceli
          yapımları seninle. Hemen adın ve mail adresinle ücretsiz kayıt ol,
          senin için seçtiğimiz içerikleri izlemeye başla!
        </span>
      </div>
    </>
  );
}

export default LandingPage;
