import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { sendTracking } from "../service/authService";
import { getCookieConsentValue } from "react-cookie-consent";

const TrackPageView = () => {
  const location = useLocation();

  useEffect(() => {
    // Her sayfa değişikliğinde URL bilgisini al ve isteği gönder
    //const currentUrl = location.pathname + location.search;
    //const currentSearch = location.search;
    const isCookieEnabled = getCookieConsentValue();
    //console.log("isCookieEnabled:", isCookieEnabled);
    //if (isCookieEnabled === "true") {
    const currentUrl = window.location.href;
    sendTracking(currentUrl);
    //}
  }, [location]);

  return <div>{/* Bileşen içeriği */}</div>;
};

export default TrackPageView;
