import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import useModal from "../components/hooks/useModal.js";
import HooxModal, { HOOX_MODAL } from "../components/HooxModal";
import { useMediaQuery } from "react-responsive";
import { apiUrl, paymentUrl } from "../util/apiConfig.js";
import {
  getUserAggrementsForPayment,
  getUserAggrementsWithCode,
} from "../service/customerService.js";
import ReactMarkdown from "react-markdown";

const FormControl = styled(Form.Control)`
  background-color: rgba(0, 0, 0, 0.8) !important;
  border-radius: 7px !important;
  resize: none;
  height: 40px;
  font: normal normal normal 14px Montserrat;
  &:focus-visible {
    outline: rgba(0, 0, 0, 0.8) !important;
  }
`;

const ContentDiv = styled.div`
  color: #fff;
  background-color: rgba(255, 255, 255, 0.25);
  border-radius: 20px;
  padding: 30px;
  font-size: 13px;
  width: 50%;
`;

const Title = styled.div`
  font-size: 23px;
  font-weight: 500;
`;

const Line = styled("hr")`
  color: #12d6df;
  opacity: 1;
`;

const CCDateField = styled(Form.Control)`
  background-color: rgba(0, 0, 0, 0.8) !important;
  border-radius: 7px !important;
  resize: none;
  height: 40px;
  margin-right: 10px;
  font: normal normal normal 14px Montserrat;
  &:focus-visible {
    outline: rgba(0, 0, 0, 0.8) !important;
  }
`;

const CCSelect = styled.select`
  width: 30%;
  background-color: rgba(0, 0, 0, 0.8) !important;
  border-radius: 7px !important;
  resize: none;
  height: 40px;
  margin-right: 10px;
  font: normal normal normal 14px Montserrat;
  color: white;
  &:focus-visible {
    outline: rgba(0, 0, 0, 0.8) !important;
  }
  border: rgb(89, 81, 116) !important;
`;

export default function PaymentDirect() {
  // get params
  const { guid, packageId } = useParams();
  const { isShowing: modalVisible, toggle: toggleModal } = useModal();
  const isMobile = useMediaQuery({ query: "(max-width: 500px)" });
  const [isLoading, setIsLoading] = useState(false);

  const [contratContent, setContratContent] = useState("");
  const [contracts, setContracts] = useState(undefined);

  const { isShowing: isShowingContract, toggle: toggleContractModal } =
    useModal();

  const sContract = useRef(null);
  const pInfo = useRef(null);

  const handleSubmit = (event) => {
    setIsLoading(true);
    const { holderName, cardNumber, expiryMonth, expiryYear, cvv } =
      Object.values(event.currentTarget.elements)
        .slice(0, 7)
        .reduce((acc, cur) => {
          acc[cur.name] = cur.value;
          return acc;
        }, {});
    // name regex
    const nameRegex = /^[a-zA-Z0-9ğüşöçıİĞÜŞÖÇ ]{2,30}$/;

    // card number regex
    const cardNumberRegex = /^(\d{4}[- ]?){3}\d{4}$/;

    // expiry month regex
    const expiryMonthRegex = /^[0-9]{2}$/;

    // expiry year regex
    const expiryYearRegex = /^[0-9]{2}$/;

    // cvv regex
    const cvvRegex = /^[0-9]{3}$/;

    if (
      !nameRegex.test(holderName) ||
      !cardNumberRegex.test(cardNumber) ||
      !expiryMonthRegex.test(expiryMonth) ||
      !expiryYearRegex.test(expiryYear) ||
      !cvvRegex.test(cvv)
    ) {
      event.preventDefault();
      toggleModal();
      return;
    }
  };

  /*   let digitInput = (event, length) => {
    let { value } = event.target;

    if (value.length > length) {
      event.target.value = value.slice(0, length);
    } else if (!/^\d$/.test(value[value.length - 1])) {
      event.target.value = value.slice(0, value.length - 1);
    }
    value = value.match(/.{1,4}/g);
    value = value.join(" ");
    event.target.value = value;
  };
 */
  const [cardNumber, setCardNumber] = useState("");

  const handleCardNumberChange = (e) => {
    // Sadece sayıları ve boşlukları tutacak şekilde formatlayın
    let formattedCardNumber = e.target.value.replace(/[^\d\s]/g, "");

    // Boşlukları ekleyerek dört grup oluşturun
    formattedCardNumber = formattedCardNumber.replace(/(\d{4})(?=\d)/g, "$1 ");

    formattedCardNumber = formattedCardNumber.slice(0, 19); // Maksimum 19 karakter (16 rakam ve 3 boşluk)
    e.target.value = formattedCardNumber;
    // State'i güncelle
    setCardNumber(formattedCardNumber);
  };

  const [securityCode, setSecurityCode] = useState("");

  const handleSecurityCodeChange = (e) => {
    // Sadece sayıları tutacak şekilde formatlayın
    let formattedSecurityCode = e.target.value.replace(/[^\d]/g, "");

    // Kodu maksimum 3 karakterle sınırlayın
    formattedSecurityCode = formattedSecurityCode.slice(0, 3);

    e.target.value = formattedSecurityCode;
    // State'i güncelle
    setSecurityCode(formattedSecurityCode);
  };

  const textInput = (event) => {
    const { value } = event.target;

    if (/^\d$/.test(value[value.length - 1])) {
      event.target.value = value.slice(0, value.length - 1);
    }
  };

  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");

  function handleMonthChange(event) {
    setMonth(event.target.value);
  }

  function handleYearChange(event) {
    setYear(event.target.value);
  }

  const months = [
    { value: "", label: "Ay" },
    { value: "01", label: "01" },
    { value: "02", label: "02" },
    { value: "03", label: "03" },
    { value: "04", label: "04" },
    { value: "05", label: "05" },
    { value: "06", label: "06" },
    { value: "07", label: "07" },
    { value: "08", label: "08" },
    { value: "09", label: "09" },
    { value: "10", label: "10" },
    { value: "11", label: "11" },
    { value: "12", label: "12" },
  ];

  const years = [
    { value: "", label: "Yıl" },
    { value: "23", label: "23" },
    { value: "24", label: "24" },
    { value: "25", label: "25" },
    { value: "26", label: "26" },
    { value: "27", label: "27" },
    { value: "28", label: "28" },
    { value: "29", label: "29" },
    { value: "30", label: "30" },
    { value: "31", label: "31" },
    { value: "32", label: "32" },
    { value: "33", label: "33" },
  ];

  function ModelContent(data) {
    setContratContent(data);
    toggleContractModal();
  }

  useEffect(() => {
    if (contracts === undefined) {
      getUserAggrementsForPayment(packageId, "Kredi Kartı") //getContracts()
        .then((response) => {
          setContracts(response.data);
        })
        .catch((error) => console.log(error));
    }
  }, [contracts]);

  return (
    <div
      style={{
        minHeight: isMobile ? "" : "60vh",
        backgroundSize: "cover",
        backgroundImage: isMobile ? "" : `url(/assets/img/hoox_girisv3.png)`,
        backgroundPosition: "right",
      }}
    >
      <div className="container" style={{ marginBottom: "10rem" }}>
        <Row
          className="d-flex justify-content-center justify-content-md-start"
          style={{ paddingTop: isMobile ? "40px" : "100px" }}
        >
          <ContentDiv style={isMobile ? { width: "85%" } : {}}>
            <Title>Kredi Kartı Ödeme</Title>
            <Line />
            <Form
              action={apiUrl + paymentUrl}
              method="post"
              encType="multipart/form-data"
              style={{ marginTop: "30px" }}
              onSubmit={handleSubmit}
            >
              <Form.Group className="mb-3">
                <Form.Label htmlFor="name">Kart Üzerindeki İsim</Form.Label>
                <FormControl
                  id="name"
                  name="holderName"
                  placeholder="İsim Soyisim"
                  onChange={textInput}
                  required
                />
              </Form.Group>

              <input type="hidden" name="customerGuid" value={guid} />
              <input type="hidden" name="packageId" value={packageId} />

              <Form.Group className="mb-3">
                <Form.Label htmlFor="kkno">Kredi kartı Numarası</Form.Label>
                <FormControl
                  id="kkno"
                  name="cardNumber"
                  placeholder="0000 0000 0000 0000"
                  onChange={(event) => handleCardNumberChange(event)}
                  value={cardNumber}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label htmlFor="expiryMonth">
                  Son Kullanma Tarihi
                </Form.Label>
                <div style={{ display: "flex" }}>
                  <CCSelect
                    value={month}
                    onChange={handleMonthChange}
                    id="expiryMonth"
                    name="expiryMonth"
                    required
                  >
                    {months.map((month) => (
                      <option key={month.value} value={month.value}>
                        {month.label}
                      </option>
                    ))}
                  </CCSelect>

                  <CCSelect
                    value={year}
                    onChange={handleYearChange}
                    id="expiryYear"
                    name="expiryYear"
                    required
                  >
                    {years.map((year) => (
                      <option key={year.value} value={year.value}>
                        {year.label}
                      </option>
                    ))}
                  </CCSelect>

                  <CCDateField
                    id="cvv"
                    name="cvv"
                    placeholder="CVV"
                    onChange={(event) => handleSecurityCodeChange(event)}
                    value={securityCode}
                    required
                  />
                </div>
              </Form.Group>

              <Row style={{ marginTop: "10px" }}>
                <Col sm="1">
                  <input required type="checkbox" ref={sContract} />
                </Col>
                <Col sm="11">
                  <label
                    onClick={() => ModelContent(contracts?.sellingContract)}
                    style={{
                      color: "#cccccc",
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                  >
                    Mesafeli Satış ve Üyelik Sözleşmesi
                  </label>{" "}
                  'ni okudum, onaylıyorum.
                </Col>
              </Row>
              <Row style={{ marginTop: "10px" }}>
                <Col sm="1">
                  <input required type="checkbox" ref={pInfo} />
                </Col>
                <Col sm="11">
                  <label
                    onClick={() => ModelContent(contracts?.preliminaryInfo)}
                    style={{
                      color: "#cccccc",
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                  >
                    Ön Bilgilendirme
                  </label>{" "}
                  'yi okudum, onaylıyorum.
                </Col>
              </Row>

              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  className="btn btn-primary checkOutButton w-100"
                  type="submit"
                  style={{
                    marginTop: "15px",
                    width: "50%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                  disabled={isLoading}
                >
                  {isLoading ? <div className="spinner"></div> : "ÖDEME YAP"}
                </Button>
              </div>
              <input type="hidden" name="deviceTypeParam" value="Web" />
            </Form>
          </ContentDiv>
        </Row>
        <HooxModal
          isShowing={modalVisible}
          hide={toggleModal}
          title={"Lütfen bütün alanları kontrol ediniz."}
          type={HOOX_MODAL.ERROR}
        >
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button variant="secondary" onClick={toggleModal}>
              Kapat
            </Button>
          </div>
        </HooxModal>

        <HooxModal
          isShowing={isShowingContract}
          hide={toggleContractModal}
          type={HOOX_MODAL.TEXT}
        >
          <ReactMarkdown>{contratContent}</ReactMarkdown>
          <Button variant="secondary" onClick={toggleContractModal}>
            Kapat
          </Button>
        </HooxModal>
      </div>
    </div>
  );
}
