import "./contracts.css";
import { getAboutUs } from "../../service/customerService";
import { useEffect } from "react";
import { useState } from "react";
import { Helmet } from "react-helmet";

function AboutUs() {
  const [aboutUs, setAboutUs] = useState([]);

  useEffect(() => {
    getAboutUs()
      .then((response) => {
        setAboutUs(JSON.parse(response.data));
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <>
      <div className="application">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Hakkımızda</title>
          <meta
            name="description"
            content="Muud medya dijital yayıncılığın Rtük onaylı tescilli markası olan Hoox.tv yeni nesil bir dijital video izleme platformdur. "
          />
        </Helmet>
      </div>
      <div
        className="container"
        style={{ marginTop: "10%", marginBottom: "10%" }}
      >
        <h1>{aboutUs.title}</h1>
        <p>{aboutUs.body}</p>
        <p>{aboutUs.footer}</p>
        <p>{aboutUs.company}</p>
      </div>
    </>
  );
}
export default AboutUs;
