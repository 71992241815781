import { useMediaQuery } from "react-responsive";
import "./welcome/styles.css";
import { useNavigate } from "react-router-dom";
function PageNotFound() {
  const navigate = useNavigate();
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 630px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

 

  return (
    <>
      {isDesktopOrLaptop && (
        <>
          <div className="container-404">
            <div className="text-container">
              <h1 style={{ fontSize: "5.2em", alignSelf: "center" }}>404</h1>
              <span
                className="error error-404"
                style={{ width: "100%", fontSize: "2.2em" }}
              >
                Aradığınız Sayfa Bulunamadı
              </span>
            </div>

            <span className="button-404" onClick={() => navigate("/")}>
              Ana sayfaya git
            </span>
          </div>
        </>
      )}
      {isTabletOrMobile && (
        <>
          <div className="container">
            <div className="text-container">
              <h1>404</h1>
              <span className="error error-404" style={{ width: "100%" }}>
                Aradığınız Sayfa Bulunamadı
              </span>
            </div>
            <span className="button-404" onClick={() => navigate("/")}>
              Ana sayfaya git
            </span>
          </div>
        </>
      )}
    </>
  );
}

export default PageNotFound;
