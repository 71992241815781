import "./contracts.css";
import { useEffect } from "react";
import { getUserAggrementsWithCode } from "../../service/customerService";
import { useState } from "react";
import ReactMarkdown from "react-markdown";
import { Helmet } from "react-helmet";

function CookiePolicy() {
  const [contracts, setContracts] = useState(undefined);

  useEffect(() => {
    if (contracts === undefined) {
      getUserAggrementsWithCode("COKI") //getUserAggrements() //getContracts()
        .then((response) => {
          setContracts(response.data.cookieProtocol);
          //console.log("contract:", contracts);
        })
        .catch((error) => console.log(error));
    }
  }, [contracts]);

  return (
    <>
      <div className="application">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Çerez Politikası</title>
          <meta
            name="description"
            content="hoox.tv çerez politikası detayları. "
          />
        </Helmet>
      </div>
      <div
        className="container"
        style={{ marginTop: "10%", marginBottom: "10%" }}
      >
        <ReactMarkdown children={contracts} />
      </div>
    </>
  );
}

export default CookiePolicy;
