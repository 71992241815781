import React from 'react'
import { Stack } from 'react-bootstrap';
import styled from 'styled-components';
import { USER_RIGHT_BUY_PACKAGE } from '../util/constants';
import { HooxButton } from './commons/styled-bootstrap';


const ContentButtonGroupStyled = styled.div`

    margin-top: 1%;

  & button:first-child {
    margin-left: 0;
  }
  & button {
    margin: 0 5px;
  }
`;

const ContentButtonGroup = ({usrRight, onClick}) => {
    
  return (
    <ContentButtonGroupStyled>
    {usrRight === USER_RIGHT_BUY_PACKAGE ?
      <HooxButton.Secondary variant='primary' className="text-start" style={{ borderRadius: '15px', backgroundColor: 'white', color: 'black', minWidth: '16 0px' }}>
        <Stack direction="horizontal" gap={2}>
          <i className='bi bi-play-fill' />
          <div className="me-auto text-center" style={{width:'100%'}}>KİRALA İZLE</div>
        </Stack>
      </HooxButton.Secondary> :

      <HooxButton.Secondary variant='outline-secondary' onClick={onClick} style={{ borderRadius: '15px', backgroundColor: 'white', color: 'black', width: '160px' }}>
        <Stack direction="horizontal" gap={2}>
          <i className='bi bi-play-fill' />
          <div className="me-auto text-center" style={{width:'100%'}}>İZLE</div>
        </Stack>
      </HooxButton.Secondary>

    }

  </ContentButtonGroupStyled>
  )
}

export default ContentButtonGroup