import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router";
import { hideError } from "../hooxSlice";
import Footer from "./Footer";
import Header from "./Header";
import { useScrollToTop } from "./hooks/useScrollToTop";
import HooxModal, { HOOX_MODAL } from "./HooxModal";
import { useLocation } from "react-router-dom";

export default function Layout() {
  useScrollToTop();

  const error = useSelector((state) => state.error);
  const status = useSelector((state) => state.status);
  const dispatch = useDispatch();
  const location = useLocation();

  //console.log("location:", location);

  const toggleErrorModal = () => {
    dispatch(hideError());
  };

  return (
    <div
      style={{
        backgroundImage:
          location.pathname == "/" ? "" : `url(/assets/img/oo.png)`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Header />
      <Outlet />
      <Footer />
      <HooxModal
        isShowing={error !== undefined && error !== null}
        hide={toggleErrorModal}
        title="Bir sorun oluştu!"
        type={HOOX_MODAL.ERROR}
      >
        {error && error.message}
      </HooxModal>
    </div>
  );
}
